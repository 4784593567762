<template>
    <div v-if="location">
        <ListItem class="u-bb">
            <div class="l-full-width l-inline l-gap-2 l-center-v">
                <pin-location-icon width="24" height="24" />
                <div class="title-container">
                    <div class="l-inline l-gap-1">
                        <h3>{{ location.name }}</h3>

                        <div
                            v-if="userHasEditRights"
                            class="l-inline l-center-v l-gap-1"
                        >
                            <div>
                                <router-link
                                    :to="
                                        '/map/location/' + location.id + '/edit'
                                    "
                                >
                                    <IconButton>
                                        <pencil-icon
                                            :name="$t('editLocation')"
                                        />
                                    </IconButton>
                                </router-link>
                            </div>

                            <div>
                                <IconButton
                                    @click="deleteLocation(location.id)"
                                >
                                    <Trash-bin-icon
                                        :name="$t('deleteLocation')"
                                    />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <br />
                    <p>{{ location.description }}</p>
                </div>
            </div>
        </ListItem>

        <AssetList :assets="assets" />

        <div v-if="hasActivityAccess" class="u-bb">
            <router-link
                :to="{
                    name: 'locationAnimalActivityChart',
                    params: { id: id },
                }"
            >
                <ListItem>
                    <div class="l-inline l-gap-2">
                        <activity-icon width="24" height="24" />

                        <div class="l-stack l-gap-1 l-center">
                            <h3>{{ $t('showActivity') }}</h3>
                        </div>
                    </div>

                    <p><i class="arrow right"></i></p>
                </ListItem>
            </router-link>
        </div>

        <asset-documents
            class="u-bb"
            :location="id"
            :show-edit-actions="userHasEditRights"
        />

        <div v-if="isSuperuser" class="u-bb">
            <div class="l-padded u-bb">{{ $t('diagnostics') }}</div>

            <router-link
                :to="{
                    name: 'locationConnectionHeatmap',
                    params: { locationId: id },
                }"
            >
                <ListItem>
                    <div class="l-inline l-gap-2">
                        <heatmap-icon width="24" height="24" />

                        <div class="l-stack l-gap-1 l-center">
                            <h3>{{ $t('connectionHeatmap') }}</h3>
                        </div>
                    </div>

                    <p><i class="arrow right"></i></p>
                </ListItem>
            </router-link>
        </div>

        <transition name="slide-from-right">
            <div v-if="isChartViewActive" class="detached-panel">
                <div
                    class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                >
                    {{ detachedPanelTitle }}

                    <router-link to=".">
                        <icon-button @click="$emit('expand')">
                            <remove-icon width="24" height="24" color="black" />
                        </icon-button>
                    </router-link>
                </div>

                <div class="detached-panel-body">
                    <router-view />
                </div>
            </div>
        </transition>

        <v-dialog />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import { httpHelper } from '@/utils'
import { commonService } from '@/service/store.service'

import ActivityIcon from '../icons/ActivityIcon'
import AssetDocuments from '../AssetDocuments'
import AssetList from '../AssetList'
import HeatmapIcon from '../icons/HeatmapIcon'
import IconButton from '../IconButton'
import ListItem from '../ListItem'
import PencilIcon from '../icons/PencilIcon'
import PinLocationIcon from '../icons/PinLocationIcon'
import RemoveIcon from '../icons/RemoveIcon'
import TrashBinIcon from '../icons/TrashBinIcon'

export default {
    name: 'LocationDetailView',
    components: {
        ActivityIcon,
        AssetDocuments,
        AssetList,
        HeatmapIcon,
        IconButton,
        ListItem,
        PencilIcon,
        PinLocationIcon,
        RemoveIcon,
        TrashBinIcon,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            search: '',
        }
    },
    computed: {
        ...mapState('locations', ['locations']),
        ...mapState('tracker', ['trackers']),
        ...mapGetters('authentication', ['hasActivityAccess', 'isSuperuser']),
        detachedPanelTitle() {
            return this.$t(`router.${this.$route.name}`)
        },
        location() {
            return this.locations.find(location => location.id == this.id)
        },
        assets() {
            return this.trackers.filter(tracker => {
                return tracker.location == this.location.id
            })
        },
        isChartViewActive() {
            return this.$route.name === 'locationAnimalActivityChart'
        },
        userHasEditRights() {
            return this.location?.permissions?.access === 'WRITE'
        },
    },
    watch: {
        location(newLocation) {
            this.setActiveLocationOnMap(newLocation)
        },
    },
    mounted() {
        this.setActiveLocationOnMap(this.location)
    },
    destroyed() {
        this.setActiveLocationOnMap(null)
    },
    methods: {
        ...mapMutations('locations', ['setActiveLocationOnMap']),
        deleteLocation(id) {
            this.$modal.show('dialog', {
                title: this.$t('confirmDelete'),
                buttons: [
                    {
                        title: this.$t('confirmOk'),
                        handler: () => {
                            httpHelper
                                .drop('/locations/' + id + '/')
                                .then(() => {
                                    commonService.populateData().then(() => {
                                        this.$modal.hide('dialog')
                                        this.$router.push('/map')
                                    })
                                })
                        },
                    },
                    {
                        title: this.$t('shared.cancel'),
                        handler: () => {
                            this.$modal.hide('dialog')
                        },
                    },
                ],
            })
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "confirmDelete": "Delete the location irreversibly?",
            "confirmOk": "Delete location",
            "editLocation": "Edit location",
            "deleteLocation": "Delete location",
            "diagnostics": "Diagnostics",
            "connectionHeatmap": "Connection Heatmap",
            "showActivity": "Show activity"
        },
        "de": {
            "confirmDelete": "Soll der Standort unwiderruflich gelöscht werden?",
            "confirmOk": "Standort löschen",
            "editLocation": "Standort bearbeiten",
            "deleteLocation": "Standort löschen",
            "diagnostics": "Diagnose",
            "connectionHeatmap": "Verbindungsqualität",
            "showActivity": "Aktivität anzeigen"
        },
        "it": {
            "confirmDelete": "Soll der Standort unwiderruflich gelöscht werden?",
            "confirmOk": "Eliminare Locazione",
            "editLocation": "Modificare Locazione",
            "deleteLocation": "Eliminare Locazione",
            "diagnostics": "Diagnostica",
            "connectionHeatmap": "Qualità della connessione",
            "showActivity": "Mostra attività"
        }
    }
</i18n>

<style lang="scss" scoped>
.title-container {
    flex: 1;

    div {
        justify-content: space-between;
    }
}
</style>
